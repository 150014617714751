// Тип, описывающий объект с ключом 'store' любого типа
import { Event, Store } from 'effector';
import { useStore } from 'effector-react';
import { useMount } from 'react-use';

interface EffectorCompound<T> {
	store: Store<T>;
	fetchData: Event<unknown>;
	refetchData: Event<unknown>;
	isLoading: Store<boolean>;
	isError: Store<Error | null>;
}

export const useStoreCompound = <T>(
	compound: EffectorCompound<T>,
): {
	data: T;
	refetchData: Event<unknown>;
	isLoading: boolean;
	isError: Error | null;
} => {
	const data = useStore(compound.store);
	const isLoading = useStore(compound.isLoading);
	const isError = useStore(compound.isError);

	useMount(compound.fetchData);

	return { data, refetchData: compound.refetchData, isLoading, isError };
};
