import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { commonPages } from '../menu';
import browserHistory from '../routes/history';
import { $auth } from '../store/auth';
import { isFrameMode } from '../helpers/helpers';

const axiosInstance = axios.create({
	baseURL: String(process.env.REACT_APP_API_URL) || 'http://localhost:4000',
	headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.request.use(
	async (config: InternalAxiosRequestConfig) => {
		const bearer = `Bearer ${localStorage.getItem($auth.STORAGE_KEY)}`;

		(config.headers as AxiosRequestHeaders).Authorization = bearer;

		return config;
	},
	(error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (axios.isAxiosError(error)) {
			if (error.response) {
				if (error.response.status === 401) {
					if (!isFrameMode()) {
						localStorage.removeItem($auth.STORAGE_KEY);
						browserHistory.replace(`../${commonPages.login.path}`);
					}
				}
			}
		}

		return Promise.reject(error);
	},
);

export default axiosInstance;
