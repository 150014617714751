import React, { createContext, FC, ReactNode, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IUserProps } from '../common/data/userDummyData';
import { useToggle } from 'react-use';

export interface AuthContextProps {
	isAuthenticated: boolean;
	toggleIsAuthenticated: (nextValue?: boolean) => void;
	updateData?(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
}

const AuthContext = createContext<AuthContextProps>(null!);

interface Props {
	children: ReactNode;
}

export const AuthContextProvider: FC<Props> = ({ children }) => {
	const [isAuthenticated, toggleIsAuthenticated] = useToggle(false);
	const [userData, setUserData] = useState<Partial<IUserProps>>({});

	const updateData = (currentPlan: number) => {
		setUserData((d) => ({ ...d, currentPlan }));
	};

	const value = useMemo(
		() => ({
			isAuthenticated,
			toggleIsAuthenticated,
			userData,
			updateData,
		}),
		[isAuthenticated, toggleIsAuthenticated, userData],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
