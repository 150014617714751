import { AxiosResponse } from 'axios';
import axiosInstance from '..';
import { IProfile, IWidget } from '../models/models';

export default class CustomerService {
	// replaced TODO: remove on api changed
	static async getUser(): Promise<AxiosResponse<string[]> | void> {
		return axiosInstance.get('/auth/me');
	}

	static async getProfile(): Promise<IProfile> {
		const { data } = await axiosInstance.get<IProfile>('/customer/profile');
		return data;
	}

	static async patchProfile(profile: IProfile): Promise<IProfile> {
		const { data } = await axiosInstance.patch('/customer/profile', profile);
		return data;
	}

	static async postAvatar(): Promise<AxiosResponse<string> | void> {
		return axiosInstance.post('/customer/profile/avatar');
	}

	static async getWidgets(): Promise<IWidget[]> {
		const { data } = await axiosInstance.get<IWidget[]>('/customer/widgets');
		return data;
	}

	static async getWidgetsById(id: string): Promise<IWidget> {
		const { data } = await axiosInstance.get('/customer/widgets/' + id);
		return data;
	}

	static async postWidgets(applicationId: string, config?: object): Promise<IWidget> {
		const { data } = await axiosInstance.post('/customer/widgets', { applicationId, config });
		return data;
	}

	static async patchWidgets(id: number, code?: string, config?: object): Promise<IWidget> {
		const { data } = await axiosInstance.patch('/customer/widgets/' + id, { code, config });
		return data;
	}

	static async deleteWidgets(id: number) {
		return axiosInstance.delete('/customer/widgets/' + id);
	}
}
