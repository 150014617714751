import React, { useContext } from 'react';
import classNames from 'classnames';
import { motion, MotionStyle } from 'framer-motion';
import Brand from '../Brand/Brand';
import Navigation from '../Navigation/Navigation';
import { activeDashboardMenu, commonPages } from '../../menu';
import ThemeContext from '../../contexts/themeContext';

import Icon from '../../components/icon/Icon';
import useAsideTouch from '../../hooks/useAsideTouch';
import { useNavigate } from 'react-router-dom';
import { $auth } from '../../store/auth';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { asideStyle } = useAsideTouch();

	const navigate = useNavigate();

	const exit = () => {
		$auth.reset();
		navigate(`../${commonPages.login.path}`);
	};

	return (
		<>
			<motion.aside
				style={asideStyle as MotionStyle}
				className={classNames(
					'aside',
					{ open: true },
					// {
					// 	'aside-touch-bar': hasTouchButton && isModernDesign,
					// 	'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
					// 	'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					// },
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					<Navigation menu={activeDashboardMenu} id='aside-dashboard' />
				</div>

				<div className='aside-foot'>
					<nav aria-label='aside-bottom-user-menu-2'>
						<div className='navigation'>
							<div
								className='navigation-item cursor-pointer'
								onClick={() =>
									window.open('https://getwidget.vpdev.ru/help/', '_blank')
								}>
								<span className='navigation-link navigation-link-pill gw-navigation-link'>
									<span className='navigation-link-info'>
										<Icon icon='InfoIcon' className='navigation-icon' />
										<span className='navigation-text'>Справочная</span>
									</span>
								</span>
							</div>
							<div className='navigation-item cursor-pointer' onClick={() => exit()}>
								<span className='navigation-link navigation-link-pill gw-navigation-link'>
									<span className='navigation-link-info'>
										<Icon icon='ExitIcon' className='navigation-icon' />
										<span className='navigation-text'>Выход</span>
									</span>
								</span>
							</div>
						</div>
					</nav>
				</div>
			</motion.aside>
		</>
	);
};

export default Aside;
