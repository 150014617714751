import React, { useContext } from 'react';
import AuthContext from '../contexts/authContext';
import Avatar from '../components/Avatar';
import { useNavigate } from 'react-router-dom';
import { dashboardMenu, userPages } from '../menu';
import Button from '../components/bootstrap/Button';
import { $userSubscription } from '../store/users/subscriptions';
import { useStoreCompound } from '../hooks/useStoreCompound';
import { $userTokens } from '../store/users/tokens';
import { $tariffs } from '../store/common/tariffs';
import { ISubscription, ITariff, IUserToken } from '../http/models/models';
import { subscribeFormat } from '../utils/dayjs.utils';

type Props = {};

const AvatarT = (props: Props) => {
	const { userData } = useContext(AuthContext);
	const tariffs = useStoreCompound($tariffs);
	const subscription = useStoreCompound($userSubscription);
	const userTokens = useStoreCompound($userTokens);
	const navigate = useNavigate();

	const isLoading = subscription.isLoading || userTokens.isLoading || tariffs.isLoading;

	return (
		<div className='d-flex align-items-center'>
			{!isLoading && subscription.data && userTokens.data && tariffs.data && (
				<div className='d-flex align-items-center mx-5'>
					<div>Использовано показов: {getTokenRatio(userTokens.data)}</div>

					<div className='mx-5'>Тариф: {getTariffName(subscription.data, tariffs.data)}</div>

					{subscription.data.endedAt && <div>Активен до {subscribeFormat(subscription.data.endedAt)}</div>}
				</div>
			)}

			{isFreeTariff() && (
				<Button
					className='mx-5 gw-pink-btn'
					color='primary'
					onClick={() => {
						navigate(`../${dashboardMenu.pricing.path}`);
					}}>
					Выберите тариф
				</Button>
			)}

			<div
				className='col d-flex align-items-center cursor-pointer'
				onClick={() => {
					navigate(`../${userPages.profile.path}`);
				}}>
				<div className='me-3'>
					<div className='text-end'>
						<div className='fw-bold fs-6 mb-0'>{localStorage.getItem('username')}</div>
						<div className='text-muted'></div>
					</div>
				</div>
				<div className='position-relative'>
					<Avatar
						srcSet={userData.srcSet}
						src={userData.src || '/default-avatar.svg'}
						size={48}
						color={userData.color}
					/>
				</div>
			</div>
		</div>
	);

	function getTokenRatio(tokens: IUserToken) {
		if (!tokens.issued) {
			return '∞';
		}

		return `${tokens.spent} / ${tokens.issued}`;
	}

	function getTariffName(subscription: ISubscription, tariffs: ITariff[]) {
		return tariffs.find((tariff) => tariff.id === subscription.tariffId)?.title ?? '---';
	}

	function isFreeTariff() {
		if (isLoading) {
			return undefined;
		}

		return subscription.data?.tariffId === tariffs.data?.[0].id;
	}
};

export default AvatarT;
