import React from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import AvatarT from '../../../gw-components/Avatar';

const DefaultHeader = () => {

	return (
		<Header>
			<HeaderLeft>
				<></>
			</HeaderLeft>
			<HeaderRight>
				{/* TODO: refactor AvatarT  */}
				<AvatarT />
			</HeaderRight>
		</Header>
	);
};

export default DefaultHeader;
