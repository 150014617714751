import React, { FC, ReactNode, useContext, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Content from '../Content/Content';
import WrapperOverlay from './WrapperOverlay';
import HeaderRoutes from '../Header/HeaderRoutes';
import ThemeContext from '../../contexts/themeContext';
import { $user } from '../../store/users/user';
import { useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';
import { $auth } from '../../store/auth';
import { isFrameMode } from '../../helpers/helpers';

interface IWrapperContainerProps {
	children: ReactNode;
	className?: string;
}

export const WrapperContainer: FC<IWrapperContainerProps> = ({ children, className, ...props }) => {
	const { rightPanel } = useContext(ThemeContext);
	return (
		<div
			className={classNames(
				'wrapper',
				{ 'wrapper-right-panel-active': rightPanel },
				className,
			)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</div>
	);
};

WrapperContainer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
WrapperContainer.defaultProps = {
	className: undefined,
};

const Wrapper = () => {
	const auth = useStore($auth.store);
	const location = useLocation();
	const isAuthRoutes = location.pathname.includes('auth');

	useEffect(() => {
		if (!isAuthRoutes && auth) {
			$user.fetchData();
		}
	}, [isAuthRoutes]);

	return (
		<>
			<WrapperContainer>
				{!isFrameMode() && <HeaderRoutes />}
				<Content />
			</WrapperContainer>
			<WrapperOverlay />
		</>
	);
};

export default Wrapper;
