import React from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthContextProvider } from './contexts/authContext';
import { ThemeContextProvider } from './contexts/themeContext';
import App from './App/App';
import HistoryRouter from './routes/historyRouter';
import browserHistory from './routes/history';
import reportWebVitals from './reportWebVitals';
import './styles/styles.scss';
import './i18n';

Modal.setAppElement('#portal-modal');
const queryClient = new QueryClient();

const children = (
	<AuthContextProvider>
		<QueryClientProvider client={queryClient}>
			<ThemeContextProvider>
				<HistoryRouter history={browserHistory}>
					<React.StrictMode>
						<App />
					</React.StrictMode>
				</HistoryRouter>
			</ThemeContextProvider>
		</QueryClientProvider>
	</AuthContextProvider>
);

const container = document.getElementById('root');
createRoot(container as Element).render(children);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
