import { createEffect, createEvent, createStore, Event, Store } from 'effector';

interface FetchingCommonOptions<T, Args> {
	requestHandler: (args: Args) => Promise<T>;
	useCache?: boolean;
}

interface FetchingEntityOptions<T, Args> extends FetchingCommonOptions<T, Args> {
	initialState: T;
	doneDataHandler: (state: T, payload: T) => T;
}

interface FetchingEntityOptionsWithoutInitialState<T, Args> extends FetchingCommonOptions<T, Args> {
	requestHandler: (args: Args) => Promise<T>;
	doneDataHandler: (state: T | null, payload: T) => T;
}

type CombinedFetchingEntityOptions<T, Args> =
	| FetchingEntityOptions<T, Args>
	| FetchingEntityOptionsWithoutInitialState<T, Args>;

function hasInitialState<T, Args>(
	options: CombinedFetchingEntityOptions<T, Args>,
): options is FetchingEntityOptions<T, Args> {
	return 'initialState' in options;
}

export function createFetchingEntity<T, Args = unknown>(
	options: FetchingEntityOptions<T, Args>,
): {
	store: Store<T>;
	fetchData: Event<Args>;
	refetchData: Event<Args>;
	reset: Event<unknown>;
	isLoading: Store<boolean>;
	isError: Store<Error | null>;
};

export function createFetchingEntity<T, Args = unknown>(
	options: FetchingEntityOptionsWithoutInitialState<T, Args>,
): {
	store: Store<T | null>;
	fetchData: Event<Args>;
	refetchData: Event<Args>;
	reset: Event<unknown>;
	isLoading: Store<boolean>;
	isError: Store<Error | null>;
};

export function createFetchingEntity<T, Args>(
	options: FetchingEntityOptions<T, Args> | FetchingEntityOptionsWithoutInitialState<T, Args>,
) {
	const { requestHandler, doneDataHandler, useCache } = options;
	const initialState = hasInitialState(options) ? options.initialState : null;
	const cache = new Map<string, T>();

	const store = createStore(initialState);
	const isError = createStore<Error | null>(null);
	const fetchData = createEvent<Args>('fetchData');
	const refetchData = createEvent<Args>('refetchData');
	const fetchDataFx = createEffect<Args, T>(requestHandler);

	store.on(fetchDataFx.doneData, (state, payload) => {
		const handler = doneDataHandler as (state: T | null, payload: T) => T;

		return handler(state, payload);
	});

	isError.on(fetchDataFx.failData, (_, error) => error);

	fetchData.watch((args) => {
		const cacheKey = JSON.stringify(args) ?? 'key_';

		if (useCache && cache.has(cacheKey)) {
			return;
		}

		fetchDataFx(args).then((data) => {
			if (useCache) {
				cache.set(cacheKey, data);
			}
		});
	});

	refetchData.watch(fetchDataFx);

	const reset = () => {
		store.reset();
		cache.clear();
		isError.reset();
	};

	return {
		store,
		fetchData,
		refetchData,
		reset,
		isLoading: fetchDataFx.pending,
		isError,
	};
}
