import React, { forwardRef, ReactElement, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ISubHeaderProps } from '../SubHeader/SubHeader';
import { IPageProps } from '../Page/Page';
import { useNavigate } from 'react-router-dom';
import { commonPages, promotionPages } from '../../menu';
import CustomerService from '../../http/services/CustomerService';
import showNotification from '../../components/extras/showNotification';
import Icon from '../../components/icon/Icon';
import { isFrameMode } from '../../helpers/helpers';
import { useStore } from 'effector-react';
import { $auth } from '../../store/auth';

interface IPageWrapperProps {
	isProtected?: boolean;
	title?: string;
	description?: string;
	children:
		| ReactElement<ISubHeaderProps>[]
		| ReactElement<IPageProps>
		| ReactElement<IPageProps>[];
	className?: string;
}

const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ isProtected, title, description, className, children }, ref) => {
		useLayoutEffect(() => {
			// @ts-ignore
			document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${
				process.env.REACT_APP_SITE_NAME
			}`;
			// @ts-ignore
			document
				?.querySelector('meta[name="description"]')
				.setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		});

		const auth = useStore($auth.store);
		const timer: { current: NodeJS.Timeout | null } = useRef(null);
		const navigate = useNavigate();

		useEffect(() => {
			if (isFrameMode()) {
				return;
			}

			const getData = async () => {
				const userData = await CustomerService.getUser().catch((error) => {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='error' size='lg' className='me-1' />
							<span>Some error</span>
						</span>,
						error.response.data.message,
					);
				});
				//setUser(userData);
			};

			if (isProtected) {
				const timerID = setTimeout(() => getData(), 100);
				timer.current = timerID;
			}

			const promotionInviteMatch = window.location.pathname.includes(
				promotionPages.invite.path,
			);

			if (!promotionInviteMatch && isProtected && !auth) {
				navigate(`../${commonPages.login.path}`);
			}

			return () => {
				clearTimeout(timer.current as NodeJS.Timeout);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
);

PageWrapper.displayName = 'PageWrapper';

PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

PageWrapper.defaultProps = {
	isProtected: true,
	title: undefined,
	description: undefined,
	className: undefined,
};

export default PageWrapper;
