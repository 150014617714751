import React, { useEffect } from 'react';
import PromotionService from '../../../http/services/PromotionService';
import { useSearchParam } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { $auth } from '../../../store/auth';
import { commonPages } from '../../../menu';

const PromotionInvitePage = () => {
	const code = useSearchParam('code');
	const navigate = useNavigate();

	useEffect(() => {
		auth();
	}, [code]);

	return (
		<div className='vh-100 vw-100 d-flex justify-content-center align-items-center'>
			Invite loading...
		</div>
	);

	async function auth() {
		if (!code) {
			return navigate('/');
		}

		const { data } = await PromotionService.redeemInvite(code);
		$auth.set(data.auth);
		navigate(`/${commonPages.builder.path}?instanceId=${data.instanceId}&view=code`);
	}
};

export default PromotionInvitePage;
