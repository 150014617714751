import React from 'react';
import { commonPages } from '../menu';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';

const headers = [
	{ path: commonPages.login.path, element: null, exact: true },
	{ path: commonPages.signUp.path, element: null, exact: true },
	{ path: commonPages.builder.path, element: null, exact: true },
	{ path: commonPages.page404.path, element: null, exact: true },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
