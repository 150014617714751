import { AxiosResponse } from 'axios';
import axiosInstance from '../';
import { AuthResponse } from '../models/models';

export default class PromotionService {
	static createInvite(
		email: string,
		instanceId: string,
	): Promise<AxiosResponse<{ code: string }>> {
		return axiosInstance.post('/promotions/invites', { email, instanceId });
	}

	static async redeemInvite(
		code: string,
	): Promise<AxiosResponse<{ auth: AuthResponse; instanceId: string }>> {
		return axiosInstance.get('/promotions/invites', { params: { code } });
	}
}
