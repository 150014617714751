import axios, { AxiosError } from 'axios';
import { ErrorResponse } from '../types';
import { FormikProps } from 'formik';
import { ResponseValidationError } from './ResponseValidationError';

export const responseHandler = async <T>(requestCallback: () => Promise<T>) => {
	try {
		return await requestCallback();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const axiosError = error as AxiosError<ErrorResponse>;

			const isBadRequest = axiosError.code === AxiosError.ERR_BAD_REQUEST;
			const errors = axiosError.response?.data?.errors;
			const isErrorsArray = Array.isArray(errors);

			if (isBadRequest && errors && isErrorsArray) {
				const formattedErrors = errors.reduce((acc, error) => {
					const field = error.property;
					const messages = Object.values(error.constraints).reverse() as string[];

					acc.push({ field, messages });

					return acc;
				}, [] as Array<{ field: string; messages: string[] }>);

				throw new ResponseValidationError(formattedErrors);
			}

			throw error;
		}

		throw new Error('An unexpected error occurred');
	}
};

export const responseValidationErrorForFormikHandler = <T>(
	formik: FormikProps<T>,
	error: ResponseValidationError,
) => {
	error.errors.forEach(({ field, messages }) => {
		formik.setFieldError(field, messages[0]);
	});
};
