import React from 'react';

const Logo = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='90'
			height='30'
			fill='none'
			viewBox='0 0 90 30'>
			<path
				fill='url(#paint0_linear_4534_4592)'
				fillRule='evenodd'
				d='M12.685 3.337H31.42c5.116 0 9.3 4.211 9.3 9.358v4.61c0 5.147-4.184 9.358-9.3 9.358H12.685c-5.115 0-9.3-4.211-9.3-9.359v-4.609c0-5.147 4.185-9.358 9.3-9.358z'
				clipRule='evenodd'></path>
			<path
				fill='url(#paint1_linear_4534_4592)'
				fillRule='evenodd'
				d='M40.72 13.693v-.345C40.72 6.158 36.963 0 27.798 0H12.924C3.624.344 0 6.158 0 13.348v.31c0-4.541 1.915-8.36 6.564-9.77 1.209-.336 2.625-.544 4.206-.55h19.147c7.795 0 10.804 4.643 10.804 10.354z'
				clipRule='evenodd'></path>
			<path
				fill='url(#paint2_linear_4534_4592)'
				fillRule='evenodd'
				d='M40.72 15v-1.617C40.72 6.193 37.098.344 27.798 0l3.385.034c9.3.344 12.924 6.159 12.924 13.35V15l-.034 1.342c0 4.507-1.82 8.352-6.53 9.77-1.224.383-2.634.551-4.24.551H20.41h9.505c7.762 0 10.77-4.645 10.77-10.355L40.721 15z'
				clipRule='evenodd'></path>
			<path
				fill='url(#paint3_linear_4534_4592)'
				fillRule='evenodd'
				d='M3.385 15v-1.308c0-5.71 3.043-10.355 10.804-10.355h-3.385c-1.607 0-3.008.206-4.24.55C1.914 5.298 0 9.151 0 13.658v2.959c0 7.19 3.624 13.005 12.924 13.348l3.385.035c-9.3-.344-12.924-6.192-12.924-13.383V15z'
				clipRule='evenodd'></path>
			<path
				fill='url(#paint4_linear_4534_4592)'
				fillRule='evenodd'
				d='M3.385 16.308v.343c0 7.19 3.624 13.005 12.924 13.349h14.873c9.3-.344 12.887-6.16 12.887-13.351l.003-.307c0 4.541-1.847 8.36-6.497 9.77-1.23.379-2.632.55-4.24.55l-12.41.001h-6.736c-7.795 0-10.804-4.645-10.804-10.355z'
				clipRule='evenodd'></path>
			<path
				fill='#fff'
				fillRule='evenodd'
				d='M14.62 13.107c2.382 0 4.314 1.944 4.314 4.341h-8.63c0-2.398 1.932-4.341 4.315-4.341zM28.919 13.107c2.383 0 4.314 1.944 4.314 4.341h-8.629c0-2.398 1.932-4.341 4.315-4.341z'
				clipRule='evenodd'></path>
			<path
				fill='#fff'
				d='M55.441 12.841c2.067 0 3.339-1.112 3.67-2.47V8.037h-3.648v1.501h1.99v.723c-.21.501-.862 1.024-2.012 1.024-1.57 0-2.554-1.102-2.554-2.726 0-1.613.984-2.714 2.444-2.714.994 0 1.724.59 2.012 1.39l1.514-.733c-.431-1.224-1.747-2.214-3.526-2.214-2.389 0-4.157 1.802-4.157 4.271 0 2.459 1.79 4.283 4.267 4.283zm10.27-3.348c0-1.68-1.304-2.992-2.996-2.992-1.724 0-3.05 1.345-3.05 3.159 0 1.757 1.248 3.181 3.161 3.181 1.238 0 2.233-.59 2.598-1.48l-1.327-.633c-.232.411-.663.667-1.249.667-.818 0-1.36-.49-1.46-1.257h4.246c.055-.167.078-.4.078-.645zm-4.29-.579c.167-.7.642-1.079 1.294-1.079.686 0 1.172.434 1.239 1.08h-2.532zm7.85 3.86c.255 0 .553-.022.774-.055v-1.313a2.876 2.876 0 01-.387.034c-.696 0-1.028-.3-1.028-.924V8.08h1.415V6.634H68.63V4.81h-1.658v.89c0 .622-.343.934-.962.934h-.078V8.08h1.04v2.436c0 1.424.84 2.258 2.3 2.258z'></path>
			<path
				fill='#AA7EDA'
				d='M60.36 15.566l-1.403 6.019-1.327-6.019h-2.078l-1.327 6.019-1.404-6.019h-1.868l2.034 8.288h2.233l1.316-5.885 1.315 5.885h2.233l2.034-8.288h-1.757zm2.477 1.669h1.658v-1.669h-1.658v1.669zm0 6.619h1.658V17.78h-1.658v6.074zm7.23-8.288v2.726c-.409-.412-1.017-.645-1.735-.645-1.714 0-3.063 1.39-3.063 3.17 0 1.769 1.316 3.17 3.074 3.17.74 0 1.403-.244 1.835-.712v.579h1.548v-8.288h-1.659zm-1.525 6.92c-.907 0-1.56-.69-1.56-1.669 0-.968.653-1.669 1.56-1.669.895 0 1.525.69 1.525 1.669 0 .99-.63 1.668-1.525 1.668zm8.755-4.706v.645c-.42-.49-1.039-.778-1.824-.778-1.536 0-2.974 1.1-2.974 2.936 0 1.791 1.371 2.97 3.007 2.97.675 0 1.26-.211 1.68-.611v.59c0 .79-.618 1.29-1.447 1.29-.73 0-1.239-.39-1.427-1.046l-1.415.7c.354 1.113 1.46 1.848 2.842 1.848 1.68 0 3.106-1.09 3.106-2.793v-5.75h-1.548zm-1.58 4.26c-.852 0-1.504-.589-1.504-1.434 0-.868.674-1.458 1.504-1.458.829 0 1.47.59 1.47 1.458 0 .845-.62 1.435-1.47 1.435zm9.95-1.401c0-1.68-1.305-2.992-2.997-2.992-1.724 0-3.05 1.346-3.05 3.159 0 1.757 1.248 3.181 3.16 3.181 1.239 0 2.234-.59 2.599-1.48l-1.327-.633c-.232.411-.663.667-1.25.667-.817 0-1.359-.49-1.458-1.257h4.245c.055-.167.077-.4.077-.645zm-4.29-.578c.166-.701.641-1.08 1.293-1.08.686 0 1.172.434 1.238 1.08h-2.531zm7.849 3.86c.254 0 .553-.023.774-.056v-1.313a2.879 2.879 0 01-.387.034c-.697 0-1.028-.3-1.028-.923v-2.437H90V17.78h-1.415v-1.824h-1.658v.89c0 .623-.343.934-.962.934h-.078v1.446h1.04v2.437c0 1.423.84 2.258 2.299 2.258z'></path>
			<defs>
				<linearGradient
					id='paint0_linear_4534_4592'
					x1='22.053'
					x2='22.053'
					y1='3.337'
					y2='26.663'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#341C2F'></stop>
					<stop offset='0.208' stopColor='#1C2134'></stop>
					<stop offset='0.901' stopColor='#1C2134'></stop>
					<stop offset='1' stopColor='#341C2F' stopOpacity='0.87'></stop>
				</linearGradient>
				<linearGradient
					id='paint1_linear_4534_4592'
					x1='33.943'
					x2='0.371'
					y1='2.508'
					y2='2.505'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#965ED2'></stop>
					<stop offset='0.432' stopColor='#BE9AE3'></stop>
					<stop offset='1' stopColor='#fff'></stop>
				</linearGradient>
				<linearGradient
					id='paint2_linear_4534_4592'
					x1='40.698'
					x2='40.698'
					y1='29.972'
					y2='0'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#965ED2'></stop>
					<stop offset='0.552' stopColor='#BE9AE3'></stop>
					<stop offset='1' stopColor='#fff'></stop>
				</linearGradient>
				<linearGradient
					id='paint3_linear_4534_4592'
					x1='1.025'
					x2='1.026'
					y1='3.337'
					y2='31.239'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#965ED2'></stop>
					<stop offset='0.478' stopColor='#BE9AE3'></stop>
					<stop offset='1' stopColor='#fff'></stop>
				</linearGradient>
				<linearGradient
					id='paint4_linear_4534_4592'
					x1='44.209'
					x2='3.385'
					y1='28.084'
					y2='28.084'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#fff'></stop>
					<stop offset='0.51' stopColor='#BE9AE3'></stop>
					<stop offset='1' stopColor='#965ED2'></stop>
				</linearGradient>
			</defs>
		</svg>
	);
};

export default Logo;
