import { AxiosResponse } from 'axios';
import { ISubscription, IUser, IUserToken } from '../models/models';
import axiosInstance from '../index';

export default class UserService {
	static getMe(): Promise<AxiosResponse<IUser>> {
		return axiosInstance.get('/users/me');
	}

	static getTransactions(): Promise<AxiosResponse<ISubscription>> {
		return axiosInstance.get('/users/transactions');
	}

	static getSubscription(): Promise<AxiosResponse<ISubscription>> {
		return axiosInstance.get('/users/subscriptions/current');
	}

	static unsubscribe(): Promise<AxiosResponse<boolean>> {
		return axiosInstance.delete('/users/subscriptions/current');
	}

	static getTokens(): Promise<AxiosResponse<IUserToken>> {
		return axiosInstance.get('/users/tokens/current');
	}
}
