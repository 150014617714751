import { createEvent, createStore } from 'effector';
import { $user } from './users/user';
import { $userSubscription } from './users/subscriptions';
import { $userTokens } from './users/tokens';
import { AuthResponse } from '../http/models/models';
import { createEffect } from 'effector/effector.umd';
import AuthService from '../http/services/AuthService';

export namespace $auth {
	export const STORAGE_KEY = 'accessToken';

	const initialToken = localStorage.getItem(STORAGE_KEY);
	const initialState = initialToken ? ({ accessToken: initialToken } as AuthResponse) : null;

	export const store = createStore<AuthResponse | null>(initialState);
	export const set = createEvent<AuthResponse>('set');
	export const reset = createEvent('reset');
	export const verifyFx = createEffect(async () => (await AuthService.verify()).data);

	{
		store.on(set, (_, value) => value);
		store.on(verifyFx.doneData, (state, value) => {
			return state && value ? { ...state, ...value } : null;
		});
		store.on(verifyFx.fail, () => null);
		store.reset(reset);

		set.watch((payload) => {
			localStorage.setItem(STORAGE_KEY, payload.accessToken);
		});

		reset.watch(() => {
			localStorage.removeItem(STORAGE_KEY);
			$user.reset();
			$userSubscription.reset();
			$userTokens.reset();
		});
	}

	{
		if (initialState) {
			verifyFx();
		}
	}
}
